import { timeFormat, numberFormat } from "@custom/index";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 80,
    },
    {
        prop: "name",
        label: "版本标题",
        showOverflowTooltip: true
    },
    {
        prop: "common",
        label: "版本内容",
        showOverflowTooltip: true
    },
    {
        label: "发布时间",
        render: (h, {row}) => {
            return row.timestamp ? timeFormat(new Date(row.timestamp),"yyyy-MM-dd HH:mm:ss") : "";
        }
    },
];